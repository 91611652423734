// Import the router
import { createWebHistory, createRouter } from 'vue-router'
import { nextTick } from 'vue'

import { preventUnauthorizedNavigation, updateOrgStoreBasedOnRoute } from '@lib/utilities/routeGuards'

import { showToast } from '@lib/plugins/Toastie'

import { pullIntendedUrlPath } from './lib/utilities/urlRedirector'

import routes from '@/client/routes'

import { useAppStore } from '@/client/store/app/app.pinia'
import { useUserStore } from '@/client/store/user.pinia'

const DEFAULT_PAGE_TITLE = 'Altruistiq'

// Not found
// import NotFound from '@/imports/ui/NotFound.vue';

// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
})
// RouterFactory.configure((router) => {
//   router.addRoute({
//     path: '*',
//     component: NotFound,
//   });
// }, -1);

router.beforeEach(async (to, from, next) => {
  if (typeof to.matched[to.matched.length - 1]?.components?.default === 'function') {
    const store = useAppStore()

    store.loaders.route = true
  }

  const nextTarget = await preventUnauthorizedNavigation(to)

  if (nextTarget) return next(nextTarget)
  if (to.meta.public) return next()

  const intendedPath = pullIntendedUrlPath()

  if (intendedPath) return next({ path: intendedPath })

  await updateOrgStoreBasedOnRoute(to)

  return next()
})

/**
 * Track page view in analytics
 */
// router.afterEach(() => {
//   const userStore = useUserStore()
//   userStore.trackPage()
// })

router.beforeResolve((to, from, next) => {
  const store = useAppStore()

  store.loaders.route = false

  next()
})

router.afterEach(to => {
  nextTick(() => {
    document.title = (to.meta.pageTitle as string) || DEFAULT_PAGE_TITLE

    const userStore = useUserStore()
    userStore.trackPage(to.meta?.category as string, to?.name as string | undefined)
  })
})

router.onError((err, to, from) => {
  const errorCodesWhichTriggerARefresh = ["Couldn't resolve component", 'Failed to fetch dynamically imported module']

  const errorHasOneOfOurCodes = errorCodesWhichTriggerARefresh.find(code => `${err}`.includes(code))

  console.log(err)

  /**
   * If we are coming from an existing route like from
   * /measure => /intensity-metrics then the from object
   * will contain a name for the route it's transitioning from.
   * In those cases we want to try to do a hard redirect to the
   * target route because it will re-download the chunks and provide a
   * _relatively_ seamless experience for the user.
   *
   * If we are coming from a route that does NOT have a name, then
   * one of two things has happened. Either something weird has happened
   * at the very start of a user's session and they have opened the site
   * on a location which for whatever reason cannot find its chunk, or
   * the user is in an endless redirect because our first case was matched,
   * but then we were unable to load the route for the same reason. In those
   * cases we were going to redirect to the measure page, but someone quickly
   * found a way into an infinite loop there so we are now just showing a warning
   * and letting the user handle it with a refresh.
   */
  if (from.name && errorHasOneOfOurCodes) {
    return window.location.replace(to.fullPath)
  }

  showToast({
    type: 'is-danger',
    message: 'The Altruistiq app has updated - please refresh to use the latest version',
  })
})

export default router
