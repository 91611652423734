<template>
  <div :class="['category-icon', { 'category-icon--padding': includePadding }]">
    <div
      v-if="includeBackground"
      class="category-icon--mask"
      :class="`has-background-${colors[category] || 'grey-2'}`"
      data-ci="category-icon-mask"
    />
    <div
      v-if="isComplete"
      class="category-icon__checked"
    >
      <CheckCircleIcon
        class="active"
        :width="20"
      />
    </div>
    <Icon
      v-if="icon"
      :icon="icon"
      pack="fa"
      :class="`has-text-${colors[category] || 'grey-2'}`"
    />
    <slot name="label" />
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  import Icon from '@components/Icon.vue'
  import CheckCircleIcon from '@components/icons/CheckCircleIcon.vue'

  import {
    SCOPE_OR_ACTIVITY_AREA_COLOR_NAMES,
    CATEGORY_ACTIVITY_ITEMS_ICONS,
  } from '@/imports/lib/constants/categoryIconConstants'
  import { CALC_METHODS_COLORS, CALC_METHODS_ICONS } from '@/imports/lib/constants/calcMethodsConstants'

  import { EMISSION_FACTORS_FILTER_ICONS } from '@/imports/lib/constants/efCatalogueConstants'

  type Props = {
    category: string
    categoryItem: string
    includePadding: boolean
    includeBackground: boolean
    isComplete?: boolean
    categoryType: 'activity' | 'calcMethod' | 'efFilter'
  }

  const props = withDefaults(defineProps<Props>(), {
    category: '',
    categoryItem: '',
    includePadding: true,
    includeBackground: true,
    categoryType: 'activity',
  })

  const colors = computed(() => {
    if (props.categoryType === 'calcMethod') {
      return CALC_METHODS_COLORS
    }
    return SCOPE_OR_ACTIVITY_AREA_COLOR_NAMES
  })

  const icon = computed(() => {
    if (props.categoryType === 'calcMethod') {
      return CALC_METHODS_ICONS[props.categoryItem]
    }

    if (props.categoryType === 'efFilter') {
      return (
        EMISSION_FACTORS_FILTER_ICONS[props.categoryItem] ||
        EMISSION_FACTORS_FILTER_ICONS[props.categoryItem.toLowerCase()]
      )
    }

    return (
      CATEGORY_ACTIVITY_ITEMS_ICONS[props.categoryItem.toLowerCase()] ||
      CATEGORY_ACTIVITY_ITEMS_ICONS[props.categoryItem] ||
      'tag'
    )
  })
</script>

<style lang="scss" scoped>
  $module: 'category-icon';

  .#{$module} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &--mask {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      z-index: 0;
      opacity: 0.1;
    }

    &__checked {
      position: absolute;
      left: -7px;
      top: -7px;
    }

    &--padding {
      padding: $grid-size-padding * 2;
    }
  }
</style>
