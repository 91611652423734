import type { AxiosInstance } from 'axios'

import { useMemoize } from '@vueuse/core'

import { BASIC_FACILITY_URI, type BasicFacilityListResponse } from '../../DTO/facilities/basic-facilities-list.dto'
import {
  BASIC_FACILITY_DETAIL_URI,
  type BasicFacilityDetailDTORes,
} from '../../DTO/facilities/basic-facility-detail.dto'
import { replaceUriParams } from '../../DTO'
import {
  BASIC_FACILITY_UPDATE_URI,
  BasicFacilityUpdateDTOReq,
  type BasicFacilityUpdateDTORes,
} from '../../DTO/facilities/basic-facility-update.dto'

import { BASIC_FACILITIES_CONTROLLER } from '../../DTO/facilities/controller.common-vars'

import type { CreatePayload } from '@/imports/@types/facilities/v2/FacilitiesStructure'
import type { CreateFacilityResponse } from '@/imports/@types/api/facility/UpdateFacilityResponse'

export const useBasicFacilitiesApi = (http: AxiosInstance) => ({
  getBasicFacilities: useMemoize((orgId: string, payload) => {
    return http.get<BasicFacilityListResponse>(BASIC_FACILITY_URI, {
      params: payload,
      headers: {
        'x-org-id': orgId,
      },
    })
  }),

  getFacilityCountries(orgId: string) {
    return http.get<{ countries: { code: string; name: string }[] }>('/facility/getFacilityCountries', {
      params: { orgId },
    })
  },

  getBasicFacility(orgId: string, facilityId: string) {
    const path = replaceUriParams(BASIC_FACILITY_DETAIL_URI, { facilityId })
    return http.get<BasicFacilityDetailDTORes>(path, {
      params: { orgId },
    })
  },

  updateBasicFacility(payload: BasicFacilityUpdateDTOReq) {
    const path = replaceUriParams(BASIC_FACILITY_UPDATE_URI, { facilityId: payload.facilityId })
    return http.patch<BasicFacilityUpdateDTORes>(path, payload)
  },

  createBasicFacility(payload: CreatePayload) {
    return http.post<CreateFacilityResponse>(BASIC_FACILITIES_CONTROLLER, payload)
  },
})
