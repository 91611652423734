// vue app
import { createApp, markRaw } from 'vue'
import 'reflect-metadata'

/**
 * Pinia is the recommended Vue3 state management tool.
 * Given our recent introduction of TypeScript support it
 * now makes sense to start migrating towards it. You can
 * now create Pinia stores for your new features though
 * be careful not to diverge existing stores which will need
 * to be fully migrated to the new system over time.
 */
import { createPinia } from 'pinia'
import { debounce } from 'ts-debounce'
import { PiniaDebounce } from '@pinia/plugin-debounce'
import { isString } from 'lodash-es'
import moment from 'moment/min/moment-with-locales'

import PrimeVue from 'primevue/config'

import Calendar from 'primevue/calendar'
import TreeTable from 'primevue/treetable'
import Column from 'primevue/column'
import Slider from 'primevue/slider'

import IfFeatureEnabled from '@components/IfFeatureEnabled.vue'
import FeatureToggle from '@components/FeatureToggle.vue'

import formValidationRules from '@lib/formValidationRules'
import initHotjar from '@lib/analytics/hotjar'
import initSegment from '@lib/analytics/segment'
import { VueIntercom } from '@lib/plugins/VueIntercom'
import { Toastie } from '@lib/plugins/Toastie'
import '@lib/analytics/sentry'

import Heading from '@reinga/Typography/Heading.vue'
import Paragraph from '@reinga/Typography/Paragraph.vue'

import { defineVueLayouts } from './layouts'

import App from '@/imports/ui/App.vue'
import { initVuePlugins } from '@/imports/ui/plugins'

import router from '@/imports/router'

import './vee-validate'

import { INTERCOM_APP_ID } from '@/client/helpers'

// Set moment locale to default to the user's browser language
moment.locale(window.navigator.language)

formValidationRules()

const pinia = createPinia()
export const app = createApp(App)

// Pinia debounce plugin initialisation
pinia.use(PiniaDebounce(debounce)).use(pluginContext => {
  // eslint-disable-next-line no-param-reassign
  pluginContext.store.router = markRaw(router)
})

app.component('IfFeatureEnabled', IfFeatureEnabled)
app.component('FeatureToggle', FeatureToggle)

app
  .use(pinia)
  .use(router)
  .use(PrimeVue)
  .use(Toastie)
  .component('Calendar', Calendar)
  .component('Column', Column)
  .component('TreeTable', TreeTable)
  .component('Slider', Slider)
  .component('Heading', Heading)
  .component('Paragraph', Paragraph)

// Important for avoiding storybook errors
app.config.compilerOptions.isCustomElement = tag => tag === 'tippy'

if (INTERCOM_APP_ID()) {
  app.use(VueIntercom, {
    appId: INTERCOM_APP_ID(),
  })
}

initVuePlugins(app)
defineVueLayouts(app)

if (
  isString(process.env.NODE_ENV) &&
  ['development', 'development:local'].includes(process.env.NODE_ENV) &&
  !!import.meta.env.VITE_USE_MOCK_SERVICE_WORKER
) {
  import('@/client/mocks/browser.js').then(({ worker }) => {
    worker.start()
  })
}

app.mount('#app')

initHotjar()
initSegment()
