<template>
  <label
    ref="inputRef"
    class="aq-input"
    data-ci-aq-input="aq-input-wrap"
    :class="[state, size, { 'mb-2': includeMargin, 'show-success-state': showSuccessState, focused: autofocus }]"
  >
    <div class="aq-input__header">
      <AqLabel
        v-if="label"
        element="span"
        :isOptional="isOptional"
      >
        {{ label }}
      </AqLabel>

      <div class="aq-input__info">
        <InfoBubble
          v-if="$slots.info"
          :float="infoPosition"
        >
          <slot name="info" />
        </InfoBubble>
      </div>
    </div>

    <div
      :class="[
        'aq-input__input-wrap',
        inputBackgroundColour,
        { 'py-2': includePadding },
        { 'no-border': !border },
        includePadding && !showSearchIcon && !$slots.prefix ? 'px-4' : 'px-3',
      ]"
    >
      <div
        v-if="$slots.prefix || showSearchIcon || showLockIcon"
        class="aq-input__prefix-wrap mr-1"
      >
        <slot
          v-if="$slots.prefix"
          name="prefix"
        />

        <i
          v-if="!$slots.prefix && showSearchIcon"
          class="far fa-search"
        />

        <i
          v-if="!$slots.prefix && showLockIcon"
          class="far fa-lock-alt"
        />
      </div>

      <input
        ref="inputElement"
        v-model="model"
        class="aq-input__input"
        data-testid="aq-input__input"
        :type="inputType"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        :class="[sizeClass, { 'pr-5': $slots.suffix }, { 'no-spinner': hideSpinner }]"
        :placeholder="placeholder"
        :name="name"
        :maxlength="maxlength"
        :pattern="pattern"
        @blur="onBlur"
        @focus="onFocus"
      />
      <div
        class="aq-input__suffix-wrap"
        data-ci="aq-input__suffix-wrap"
        :style="{ right: `${suffixPositionRight}px` }"
      >
        <template v-if="$slots.suffix">
          <slot name="suffix" />
        </template>

        <template v-if="!$slots.suffix">
          <FieldStateIcons :state="state" />
        </template>

        <button
          v-if="type === 'search' && !isEmpty"
          class="aq-input__search-delete"
          @click="clearText"
        >
          <i class="fa-light fa-circle-xmark" />
        </button>

        <button
          v-if="type === 'password'"
          class="aq-input__password-reveal"
          type="button"
          @click="revealPassword = !revealPassword"
        >
          <i
            v-if="!revealPassword"
            class="fa fa-eye"
          />

          <i
            v-if="revealPassword"
            class="fa fa-eye-slash"
          />
        </button>
      </div>
    </div>

    <AqHelpText
      v-if="$slots.helpText"
      :size="size"
      :success="state === 'success'"
      data-ci="input-field-help-text"
    >
      <slot name="helpText" />
    </AqHelpText>

    <AqError
      v-if="state === 'failure'"
      :size="size"
      data-ci="input-field-message"
      >{{ errorMessage }}
    </AqError>
  </label>
</template>

<script lang="ts">
  import { defineComponent, toRefs, ref } from 'vue'
  import { useField } from 'vee-validate'

  import AqError from '@components/form/AqError.vue'
  import AqHelpText from '@components/form/AqHelpText.vue'
  import AqLabel from '@components/form/AqLabel.vue'
  import FieldStateIcons from '@components/form/FieldStateIcons.vue'
  import InfoBubble from '@components/form/InfoBubble.vue'

  export default defineComponent({
    name: 'AqInput',

    components: {
      AqError,
      AqHelpText,
      AqLabel,
      FieldStateIcons,
      InfoBubble,
    },

    props: {
      name: {
        type: String,
        required: true,
      },

      label: {
        type: String,
        default: '',
      },

      placeholder: {
        type: String,
        default: '',
      },

      modelValue: {
        type: [String, Number],
        default: '',
      },

      rules: {
        type: String,
        default: '',
      },

      autofocus: {
        type: Boolean,
      },

      disabled: {
        type: Boolean,
      },

      invalid: {
        type: Boolean,
      },

      border: {
        type: Boolean,
        default: true,
      },

      infoPosition: {
        type: String,
        default: 'right',
      },

      inputBackgroundColour: {
        type: String,
        default: 'has-background-white',
        validator(value: string) {
          return ['has-background-primary-active', 'has-background-white'].includes(value)
        },
      },
      type: {
        type: String,
        default: 'text',
        // Validate that our input type matches an accepted HTML input type
        validator(value: string) {
          return ['email', 'text', 'password', 'tel', 'number', 'date', 'search'].includes(value)
        },
      },

      size: {
        type: String,
        default: 'medium',
        // Our design system specifies medium and large as size types for inputs
        validator(value: string) {
          return ['small', 'medium', 'large'].includes(value)
        },
      },

      mode: {
        type: String,
        default: 'delayed',
        /**
         * Check if the given mode matches one of our allowed modes:
         *
         * delayed: will validate on each input event but will not display failure or success states until the blur event fires
         * aggressive: will validate on each input event and will display failure and success states as soon as they're encountered.
         */
        validator(value: string) {
          return ['delayed', 'aggressive'].includes(value)
        },
      },

      showSearchIcon: {
        type: Boolean,
      },

      showLockIcon: {
        type: Boolean,
      },

      includeMargin: {
        type: Boolean,
        default: true,
      },

      hideSpinner: {
        type: Boolean,
        default: false,
      },

      showSuccessState: {
        type: Boolean,
        default: true,
      },

      suffixPositionRight: {
        type: Number,
        default: 16,
      },

      includePadding: {
        type: Boolean,
        default: true,
      },

      step: {
        type: String,
        default: '1',
      },

      min: {
        type: [Number, String],
        default: null,
      },

      max: {
        type: [Number, String],
        default: null,
      },

      maxlength: {
        type: String,
        default: null,
      },

      pattern: {
        type: String,
        default: null,
      },

      isOptional: {
        type: Boolean,
        default: false,
      },

      /**
       * When this is true the value of the input will be selected when the user focuses the element. This
       * can be useful on forms with initial values which will need updating
       */
      selectAllOnFocus: {
        type: Boolean,
      },
    },

    emits: ['update:modelValue', 'onInput', 'onFocus', 'blur'],

    setup(props, { emit }) {
      const { modelValue, name, rules } = toRefs(props)
      const { errorMessage, meta, handleChange, handleBlur } = useField(name, rules.value, {
        initialValue: modelValue.value,
      })

      const revealPassword = ref(false)

      const onBlur = (e: FocusEvent) => {
        emit('blur', e)

        handleBlur(e)
      }

      return {
        errorMessage,
        handleBlur,
        handleChange,
        meta,
        revealPassword,
        onBlur,
      }
    },

    computed: {
      model: {
        get() {
          const { modelValue } = this

          return modelValue
        },

        set(value: string) {
          const { $emit, handleChange } = this

          handleChange(value)

          $emit('update:modelValue', value)
          $emit('onInput', value)
        },
      },

      isEmpty() {
        const { modelValue } = this

        return !modelValue?.toString().length
      },

      // Returns the current state of the input
      state() {
        const { disabled, errorMessage, isEmpty, meta, mode, rules, invalid } = this
        const modeIsAggressive = mode === 'aggressive'
        const modeIsDelayed = mode === 'delayed'

        if (disabled) return 'disabled'

        if (invalid) return 'failure'

        if (isEmpty && !meta.dirty) return 'empty'

        // Success states
        if (rules && modeIsAggressive && meta.valid && meta.dirty) return 'success'

        if (rules && modeIsDelayed && meta.valid && meta.touched) return 'success'

        // Error states
        if (rules && modeIsAggressive && meta.dirty && errorMessage) return 'failure'

        if (rules && modeIsDelayed && meta.touched && errorMessage) return 'failure'

        return ''
      },

      // Returns the classNames defined by the required size.
      // Size prop defaults to medium
      sizeClass() {
        const { size } = this

        if (size === 'large') return 'is-size-6'
        if (size === 'small') return 'is-size-8'

        return 'is-size-7'
      },

      inputType() {
        if (this.type === 'password' && this.revealPassword) {
          return 'text'
        }

        return this.type
      },
    },

    async mounted() {
      await this.$nextTick()

      const { autofocus, $refs } = this

      // Autofocus the input if it's set to do so
      if (autofocus && $refs.inputRef) this.focus()
    },

    methods: {
      /**
       * Focus the input field
       */
      focus() {
        if (this.$refs.inputRef instanceof HTMLElement) {
          this.$refs.inputRef.focus()
        }
      },

      clearText() {
        const { $emit } = this
        if (this.type === 'search' && !this.isEmpty) {
          $emit('update:modelValue', '')
        }
      },

      onFocus() {
        this.$emit('onFocus')

        if (this.selectAllOnFocus && this.$refs.inputElement instanceof HTMLInputElement) {
          this.$refs.inputElement.select()
        }
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-input';

  .#{$module} {
    display: block;
    color: $text-primary;

    &__header {
      display: flex;
      flex-flow: row nowrap;
    }

    &__info {
      margin-left: auto;
    }

    &__input-wrap {
      background-color: $grey-8;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border-radius: 6px;
      color: $grey-1;
      border: 1px solid $grey-4;
      position: relative;

      &.no-border {
        border: none !important;
      }

      &.has-background-primary-active {
        background-color: $active-link-bg;
        color: $grey-3;
        border: none !important;
      }

      .#{$module}.disabled & {
        border: 1px solid $grey-6;
        color: $grey-3;
      }

      .#{$module}.empty & {
        border: 1px solid $grey-4;
        color: $grey-3;

        &:hover {
          border: 1px solid $kelp;
        }
      }

      .#{$module}:focus-within & {
        border: 1px solid $kelp;
        box-shadow: 0px 0px 0px 4px #d4e9ea;
      }

      .#{$module}.failure & {
        border: 1px solid $red-base;
      }

      .#{$module}.success.show-success-state & {
        border: 1px solid $green-base;
      }
    }

    &__input {
      appearance: none;
      display: block;
      outline: none;
      border: none;
      color: inherit;
      background-color: transparent;
      flex-basis: 100%;
      flex-shrink: 1;
      width: 100%;
      padding: 0;

      .#{$module}.disabled & {
        cursor: not-allowed;
      }

      &.no-spinner[type='number']::-webkit-outer-spin-button,
      &.no-spinner[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &.no-spinner[type='number'] {
        -moz-appearance: textfield;
      }

      &::placeholder {
        color: $grey-3;
        font-weight: 500;
      }
    }

    &__prefix-wrap {
      flex-basis: 18px;
      flex-shrink: 0;
      color: $grey-2;
    }

    &__suffix-wrap {
      width: fit-content;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }

    &__search-delete {
      color: $grey-2;
    }
  }
</style>
