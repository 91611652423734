import type { AxiosInstance } from 'axios'
import { useMemoize } from '@vueuse/core'

import type { AddFranchiseDTORes, AddFranchiseDTO } from '@lib/DTO/franchises/add-franchise.dto'
import type { GetFranchisesDTO, GetFranchisesDTORes } from '@lib/DTO/franchises/get-franchises.dto'
import { RESEND_INVITE_PATH } from '@lib/DTO/franchises/resend-invite.dto'
import {
  type GetCompletedFranchiseRequestsDTO,
  type GetCompletedFranchiseRequestsDTORes,
  GET_RESPONDED_FRANCHISES_PATH,
} from '@lib/DTO/franchises/get-completed-franchises-requests.dto'
import {
  GET_YEARS_WITH_FRANCHISE_REQUESTS,
  type GetYearsWithFranchiseRequestsDTORes,
} from '@lib/DTO/franchises/get-years-with-franchise-requests.dto'

import type {
  SendFranchiseInvitesDTO,
  SendFranchiseInvitesDTORes,
} from '@lib/DTO/franchises/send-franchise-invites.dto'

import { type GetFranchiseRequestFootprintResponse } from '@lib/DTO/franchise-requests/get-franchise-request-footprint.dto'

import { AddFranchiseRequestReviewDTO } from '@lib/DTO/franchise-requests/add-franchise-request-review.dto'

import { FRANCHISE_REQUESTS_CONTROLLER } from '@lib/DTO/franchise-requests/franchise-requests.common-vars'

import { replaceUriParams } from '@lib/DTO'
import { GetUninvitedFranchisesDTO } from '@lib/DTO/franchises/get-uninvited-franchises.dto'
import { DELETE_FRANCHISE_PATH } from '@lib/DTO/franchises/delete-franchise.dto'

import { EditFranchiseDTO, EDIT_FRANCHISE_PATH } from '@lib/DTO/franchises/edit-franchise.dto'

import {
  GetFranchiseRequestsRespondedPercentageDTO,
  GET_FRANCHISE_REQUESTS_RESPONDED_PERCENTAGE_PATH,
  type GetFranchiseRequestsRespondedPercentageResponse,
} from '@lib/DTO/franchise-requests/get-franchise-requests-responded-percentage.dto'

import type { GetFranchiseRequestsPendingReviewsCountResponse } from '@lib/DTO/franchise-requests/get-franchise-request-pending-reviews.dto'
import {
  GET_FRANCHISE_REQUESTS_PENDING_REVIEWS_COUNT_PATH,
  GetFranchiseRequestsPendingReviewsCountDTO,
} from '@lib/DTO/franchise-requests/get-franchise-request-pending-reviews.dto'

import { useOrganizationStore } from '@/client/store/organization.pinia'
import type { GetFranchiseRequestProgressMetaDTORes } from '@/imports/lib/DTO/franchise-requests/get-progress-meta.dto'

const FRANCHISES_API_BASE_BATH = 'franchises'

export const useFranchisesApi = (http: AxiosInstance) => ({
  getFranchises(payload: GetFranchisesDTO = {}) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetFranchisesDTORes>(FRANCHISES_API_BASE_BATH, {
      params: payload,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getAvailableRequestYears: useMemoize(() => {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetYearsWithFranchiseRequestsDTORes>(GET_YEARS_WITH_FRANCHISE_REQUESTS, {
      headers: {
        'x-org-id': orgId,
      },
    })
  }),

  createFranchise(payload: AddFranchiseDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.post<AddFranchiseDTORes>(FRANCHISES_API_BASE_BATH, payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  bulkInviteFranchises(payload: SendFranchiseInvitesDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.post<SendFranchiseInvitesDTORes>('franchises/invites', payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getUninvitedFranchises(payload: GetUninvitedFranchisesDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetFranchisesDTORes>('franchises/uninvited', {
      params: payload,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  resendInvite(id: string) {
    const orgId = useOrganizationStore().id || ''

    const path = replaceUriParams(RESEND_INVITE_PATH, { inviteId: id })

    return http.post(
      path,
      {},
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },

  deleteFranchise(franchiseId: string) {
    const orgId = useOrganizationStore().id || ''
    const url = replaceUriParams(DELETE_FRANCHISE_PATH, { franchiseId })

    return http.delete(url, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  updateFranchise(payload: EditFranchiseDTO, franchiseId: string) {
    const orgId = useOrganizationStore().id || ''
    const url = replaceUriParams(EDIT_FRANCHISE_PATH, { franchiseId })

    return http.patch<GetFranchisesDTORes>(url, payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getStats(params: { orgId: string }) {
    return http.get<GetFranchiseRequestProgressMetaDTORes>('/franchise-requests/progress-meta', {
      params,
    })
  },

  getFranchiseDataResponses(params: GetCompletedFranchiseRequestsDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetCompletedFranchiseRequestsDTORes>(GET_RESPONDED_FRANCHISES_PATH, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getTotalPendingReviews(params: GetFranchiseRequestsPendingReviewsCountDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetFranchiseRequestsPendingReviewsCountResponse>(
      GET_FRANCHISE_REQUESTS_PENDING_REVIEWS_COUNT_PATH,
      {
        params,
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },

  getPercentageShared(params: GetFranchiseRequestsRespondedPercentageDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetFranchiseRequestsRespondedPercentageResponse>(GET_FRANCHISE_REQUESTS_RESPONDED_PERCENTAGE_PATH, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getFranchiseRequestFootprint(requestId: string) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetFranchiseRequestFootprintResponse>(`${FRANCHISE_REQUESTS_CONTROLLER}/${requestId}/footprint`, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  updateFranchiseRequestReview(params: AddFranchiseRequestReviewDTO, franchiseId: string) {
    const orgId = useOrganizationStore().id || ''

    return http.put<AddFranchiseRequestReviewDTO>(`${FRANCHISE_REQUESTS_CONTROLLER}/${franchiseId}/review`, params, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
