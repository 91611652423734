import { IsUUID, IsNumber } from 'class-validator'

import { ValidateDTO } from '../..'

import { DATA_IMPORTER_AUTH_CONTROLLER } from './controller.common-vars'

export const POST_DATA_IMPORTER_JWT_TOKEN_SUBPATH = 'jwt/generate'
export const POST_DATA_IMPORTER_JWT_TOKEN_URI = `${DATA_IMPORTER_AUTH_CONTROLLER}/${POST_DATA_IMPORTER_JWT_TOKEN_SUBPATH}`

export class PostDataImporterJWTTokenDTO extends ValidateDTO {
  @IsUUID()
  dataSourceId!: string

  @IsNumber()
  calcMethodId!: number
}
